import { CircularProgress } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { LicenseInfo } from "@mui/x-license-pro";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import React, { lazy, Suspense, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Route, Routes } from "react-router-dom";
import {
  arbitrum,
  avalanche,
  base,
  bsc,
  mainnet,
  optimism,
  polygon,
  baseSepolia,
} from "viem/chains";
import { WagmiProvider } from "wagmi";
import ProtectedRoute from "./components/Common/ProtectedRoute";
import AppHelmet from "./components/Header/AppHelmet";
import LayoutContainer from "./components/Layout";
import ErrorPage from "./pages/home/ErrorPage";
import { authActions } from "./redux/features/authSlice";
import { snackbarActions } from "./redux/features/snackbarSlice";
import { themeActions } from "./redux/features/themeSlice";
import { snackbarStateSelector } from "./redux/selectors/snackbarSelector";
import { LocalStorages } from "./utils/localStorages";
import { AppRoutes } from "./utils/routes";

import "./App.css";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY as string);
const Profile = lazy(() => import("./pages/accounts/profile/Profile"));
const Home = lazy(() => import("./pages/home/Home"));
// const Landing = lazy(() => import("./pages/home/Landing"));

const LiveDexMaster = lazy(
  () => import("./pages/dex/liveDexMaster/LiveDexMaster")
);

const TokenGenius = lazy(() => import("./pages/tokens/TokenGenius"));
const TokenGeniusIndex = lazy(() => import("./pages/tokens/TokenGeniusIndex"));
const AIAgentGenius = lazy(() => import("./pages/tokens/AIAgentGenius"));
const WalletProfile = lazy(() => import("./pages/wallets/WalletDeepExplorer"));
const WalletIndex = lazy(() => import("./pages/wallets/WalletIndex"));
const Watchlist = lazy(() => import("./pages/accounts/watchlist/Watchlist"));

const SmartAlerts = lazy(
  () => import("./pages/accounts/smartalerts/SmartAlerts")
);
const CreateSmartAlert = lazy(
  () => import("./pages/accounts/smartalerts/CreateSmartAlert")
);
const NarrativePulse = lazy(
  () => import("./pages/dex/liveDexMaster/NarrativePulse")
);
const SmartDexStream = lazy(
  () => import("./pages/dex/smartDexStream/SmartDexStream")
);

const SmartHoldings = lazy(() => import("./pages/smartHoldings/SmartHoldings"));
const DexFlows = lazy(() => import("./pages/dex/dexFlows/DexFlows"));
const CexNetTransactions = lazy(() => import("./pages/cex/NetTransactions"));
const NotFound = lazy(() => import("./pages/home/NotFound"));
const TopAlphaHunters = lazy(
  () => import("./pages/leaderboard/TopAlphaHunters")
);
const WhaleTxnStream = lazy(() => import("./pages/whaleTxn/WhaleTxnStream"));
const OnChainAI = lazy(() => import("./pages/gpt/OnChainAI"));
const Disconnect = lazy(() => import("./pages/accounts/auth/Disconnect"));
const Upgrade = lazy(() => import("./pages/upgrade/Upgrade"));

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const queryClient = new QueryClient();

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID as string;

// 2. Create wagmiConfig
const metadata = {
  name: "Web3Modal",
  description: "Alphanomics Web3Modal",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [
  mainnet,
  base,
  arbitrum,
  bsc,
  polygon,
  optimism,
  avalanche,
  baseSepolia,
] as const;

const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  auth: {
    email: false,
  },
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig,
  projectId,
  defaultChain: mainnet,
});

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const snackbarState = useSelector(snackbarStateSelector);

  const theme = LocalStorages.getTheme();

  const handleToastClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    dispatch(snackbarActions.closeSnackbar({}));
  };

  useEffect(() => {
    dispatch(themeActions.setTheme({ theme: theme as string }));
    const access_token = LocalStorages.getAccessToken();
    dispatch(authActions.setAccessToken(access_token as string));
    document.body.setAttribute("data-theme", theme as string);
  }, []);

  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary key={location.pathname} fallback={<ErrorPage />}>
          <AppHelmet />
          <div className="app-container">
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={snackbarState.open}
              autoHideDuration={snackbarState.duration}
              onClose={handleToastClose}
            >
              <Alert
                onClose={handleToastClose}
                severity={snackbarState.color}
                sx={{ width: "100%" }}
                className="dm-sans-500 toast-text"
              >
                {snackbarState.content}
              </Alert>
            </Snackbar>

            <LayoutContainer>
              <Suspense
                fallback={
                  <div className="suspense-container">
                    <CircularProgress />
                  </div>
                }
              >
                <Routes>
                  <Route path="*" element={<NotFound />} />
                  {/*<Route path={AppRoutes.landing} element={<Landing />} />*/}
                  <Route path={AppRoutes.disconnect} element={<Disconnect />} />
                  <Route element={<ProtectedRoute />}>
                    <Route path={AppRoutes.profile} element={<Profile />} />
                    <Route path={AppRoutes.dashboard} element={<Home />} />
                    <Route path={AppRoutes.home} element={<LiveDexMaster />} />
                    <Route
                      path={AppRoutes.liveDexMaster}
                      element={<LiveDexMaster />}
                    />
                    <Route
                      path={AppRoutes.tokenGenius}
                      element={<TokenGenius />}
                    />
                    <Route
                      path={AppRoutes.tokenGeniusIndex}
                      element={<TokenGeniusIndex />}
                    />
                    <Route
                      path={AppRoutes.aiAgentGenius}
                      element={<AIAgentGenius />}
                    />
                    <Route
                      path={AppRoutes.walletProfile}
                      element={<WalletProfile />}
                    />
                    <Route
                      path={AppRoutes.walletIndex}
                      element={<WalletIndex />}
                    />
                    <Route path={AppRoutes.watchlist} element={<Watchlist />} />
                    <Route
                      path={AppRoutes.smartAlert}
                      element={<SmartAlerts />}
                    />
                    <Route
                      path={AppRoutes.createSmartAlert}
                      element={<CreateSmartAlert />}
                    />
                    <Route
                      path={AppRoutes.createSmartAlertWithAddress}
                      element={<CreateSmartAlert />}
                    />
                    <Route
                      path={AppRoutes.updateSmartAlert}
                      element={<CreateSmartAlert />}
                    />
                    <Route
                      path={AppRoutes.narrativePulse}
                      element={<NarrativePulse />}
                    />
                    <Route
                      path={AppRoutes.smartDexStream}
                      element={<SmartDexStream />}
                    />
                    <Route
                      path={AppRoutes.smartHoldings}
                      element={<SmartHoldings />}
                    />
                    <Route path={AppRoutes.dexFlows} element={<DexFlows />} />
                    <Route
                      path={AppRoutes.cexNetTransactions}
                      element={<CexNetTransactions />}
                    />
                    <Route
                      path={AppRoutes.leaderBoard}
                      element={<TopAlphaHunters />}
                    />
                    <Route
                      path={AppRoutes.whaleTrades}
                      element={<WhaleTxnStream />}
                    />
                    <Route path={AppRoutes.onchainAI} element={<OnChainAI />} />
                    <Route path={AppRoutes.plan} element={<Upgrade />} />
                  </Route>
                </Routes>
              </Suspense>
            </LayoutContainer>
          </div>
        </ErrorBoundary>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default App;
