import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  ITopTokenWhale,
  ITopTokenWhales,
  ITopTokenWhalesQuery,
  IWhaleTrades,
  IWhaleTradesQuery,
} from "../../@types/redux";
import { StatisticsAPI } from "../../utils/api/StatisticsAPI";
import { WhaleDexStreamOptions } from "../../utils/constants";

export const whaleTradesDefaultQuery = {
  chain: "",
  threshold: "50000",
  page: 1,
  page_size: 50,
  total_limit: 10000,
  exclude_ncg_tokens: true,
};

const initialState = {
  isRefreshing: false,
  loading: false,
  total: 0,
  transactions: [],
  query: whaleTradesDefaultQuery,
  topTokenWhales: {
    loading: false,
    total: 0,
    transactions: [] as ITopTokenWhale[],
    maxValue: 0,
    query: {
      chain: "",
      category: WhaleDexStreamOptions.Buying,
      num_hrs: "6",
      threshold_value: "10000",
      exclude_sn_tokens: true,
    },
  },
};

const WhaleTransactionsSlice = createSlice({
  name: "whaleTransactions",
  initialState,
  reducers: {
    getList: (state, _: PayloadAction<ITopTokenWhalesQuery>) => {
      state.topTokenWhales.loading = true;
      state.topTokenWhales.total = 0;
      state.topTokenWhales.transactions = [];
    },

    setList: (state, action: PayloadAction<ITopTokenWhales>) => {
      state.topTokenWhales.loading = false;
      state.topTokenWhales.total = action.payload.total;
      state.topTokenWhales.transactions = action.payload.transactions;
      if (action.payload.transactions.length > 0) {
        state.topTokenWhales.maxValue = action.payload.transactions.reduce(
          (max, current: ITopTokenWhale) =>
            current.volume > max ? current.volume : max,
          action.payload.transactions[0].volume
        );
      }
    },

    setQuery: (state, action: PayloadAction<ITopTokenWhalesQuery>) => {
      state.topTokenWhales.query = action.payload;
    },

    getDexTxnsList: (state, _: PayloadAction<IWhaleTradesQuery>) => {
      if (!state.isRefreshing) {
        state.loading = true;
        state.total = 0;
        state.transactions = [];
      }
    },

    setDexTxnsList: (state, action: PayloadAction<IWhaleTrades>) => {
      state.isRefreshing = false;
      state.loading = false;
      state.total = action.payload.total;
      state.transactions = action.payload.transactions;
    },

    setDexTxnsQuery: (state, action: PayloadAction<IWhaleTradesQuery>) => {
      state.query = action.payload;
    },

    resetFilters: (state, _) => {
      state.isRefreshing = true;
      state.query = {
        ...whaleTradesDefaultQuery,
        threshold: state.query.threshold,
        exclude_ncg_tokens: state.query.exclude_ncg_tokens,
      };
    },
  },
});

const whaleTradesActions = WhaleTransactionsSlice.actions;
const whaleTradesReducer = WhaleTransactionsSlice.reducer;

export { whaleTradesActions, whaleTradesReducer };

// Sagas

function* getTopTokenWhales(action: any): any {
  const res = yield call(StatisticsAPI.getWhaleSegments, {
    ...action.payload,
  });

  if (!res.error) {
    yield put(
      whaleTradesActions.setList({
        transactions: res.data,
        total: res.data.length,
      })
    );
  } else {
    yield put(
      whaleTradesActions.setList({
        transactions: [],
        total: 0,
      })
    );
  }
}

function* getDexTxnsList(action: any): any {
  const res = yield call(StatisticsAPI.getWhaleDexTransactions, {
    ...action.payload,
  });

  if (!res.error) {
    yield put(
      whaleTradesActions.setDexTxnsList({
        transactions: res.data.results,
        total: res.data.total_count,
      })
    );
  } else {
    yield put(
      whaleTradesActions.setDexTxnsList({
        transactions: [],
        total: 0,
      })
    );
  }
}

export function* whaleTradesSaga() {
  yield all([takeLatest(whaleTradesActions.getList, getTopTokenWhales)]);
  yield all([takeLatest(whaleTradesActions.getDexTxnsList, getDexTxnsList)]);
}
