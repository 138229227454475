import { IDexFlowsQuery } from "../../@types/api";
import { BaseAPI } from "./BaseAPI";

interface ITokenStatistics {
  chain: string;
  num_hrs: number;
  category: string;
  type: string;
}

export class DexFlowsAPI {
  static getDexFlows = async ({ chain, type }: IDexFlowsQuery) => {
    return BaseAPI.callAPI({
      url: `/dex/${type}_flows/data/`,
      params: {
        chain,
      },
    });
  };

  static getDexFlowsStatistics = async ({
    chain,
    num_hrs,
    category,
    type,
  }: ITokenStatistics) => {
    return BaseAPI.callAPI({
      url: `/dex/${type}_flows/stats/`,
      params: {
        chain,
        num_hrs,
        category,
      },
    });
  };
}
