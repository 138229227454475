import { all } from "redux-saga/effects";
import { authReducer, authSaga } from "./authSlice";
import {
  cexTransactionsReducer,
  cexTransactionsSaga,
} from "./cexTransactionsSlice";
import { dexFlowsReducer, dexFlowsSaga } from "./dexFlowsSlice";
import {
  dexTransactionsReducer,
  dexTransactionsSaga,
} from "./dexTransactionsSlice";
import { networkStatsReducer, networkStatsSaga } from "./networkStatsSlice";
import { sidebarReducer } from "./sidebarSlice";
import { smartHoldingsReducer, smartHoldingsSaga } from "./smartHoldingsSlice";
import { smartWhalesReducer, smartWhalesSaga } from "./smartWhalesSlice";
import { snackbarReducer } from "./snackbarSlice";
import { statisticsReducer, statisticsSaga } from "./statisticsSlice";
import { themeReducer } from "./themeSlice";
import { tokenInfoReducer, tokenInfoSaga } from "./tokenInfoSlice";
import { walletReducer, walletSaga } from "./walletSlice";
import { watchlistReducer, watchlistSaga } from "./watchlistSlice";
import { whaleTradesReducer, whaleTradesSaga } from "./whaleTradesSlice";

export const rootReducer = {
  auth: authReducer,
  theme: themeReducer,
  snackbar: snackbarReducer,
  sidebar: sidebarReducer,
  networkStats: networkStatsReducer,
  cexTransactions: cexTransactionsReducer,
  dexTransactions: dexTransactionsReducer,
  statistics: statisticsReducer,
  tokenInfo: tokenInfoReducer,
  watchlist: watchlistReducer,
  smartWhales: smartWhalesReducer,
  smartHoldings: smartHoldingsReducer,
  dexFlows: dexFlowsReducer,
  wallet: walletReducer,
  whaleTrades: whaleTradesReducer,
};

export function* rootSaga() {
  yield all([
    authSaga(),
    networkStatsSaga(),
    cexTransactionsSaga(),
    dexTransactionsSaga(),
    statisticsSaga(),
    tokenInfoSaga(),
    watchlistSaga(),
    smartWhalesSaga(),
    smartHoldingsSaga(),
    dexFlowsSaga(),
    walletSaga(),
    whaleTradesSaga(),
  ]);
}
