import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { IDexFlows, IDexFlowsQuery } from "../../@types/redux";
import { DexFlowsAPI } from "../../utils/api/DexFlowsAPI";

const initialState = {
  isRefreshing: false,
  loading: false,
  results: [],
};

const dexFlowsSlice = createSlice({
  name: "totalFlows",
  initialState,
  reducers: {
    getList: (state, _: PayloadAction<IDexFlowsQuery>) => {
      if (!state.isRefreshing) {
        state.loading = true;
        state.results = [];
      }
    },

    setList: (state, action: PayloadAction<IDexFlows>) => {
      state.isRefreshing = false;
      state.loading = false;
      state.results = action.payload.results;
    },

    resetFilters: (state, _) => {
      state.isRefreshing = true;
    },
  },
});

const dexFlowsAction = dexFlowsSlice.actions;
const dexFlowsReducer = dexFlowsSlice.reducer;

export { dexFlowsAction, dexFlowsReducer };

// Sagas

function* getDexFlows(action: PayloadAction<IDexFlowsQuery>): any {
  const res = yield call(DexFlowsAPI.getDexFlows as any, {
    ...action.payload,
  });

  if (!res.error) {
    yield put(
      dexFlowsAction.setList({
        results: res.data,
      })
    );
  } else {
    yield put(
      dexFlowsAction.setList({
        results: [],
      })
    );
  }
}

export function* dexFlowsSaga() {
  yield all([takeLatest(dexFlowsAction.getList, getDexFlows)]);
}
